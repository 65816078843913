<template>
    <div class="player-container">
        <div :class="options.playerOnly ? 'player-only-layout' : 'player-layout'">
            <!-- START: Sidebar -->
            <aside class="sidebar">
                <div
                    class="sidebar-label"
                    :class="{
                        'sidebar-label--large': availableEvents.length === 1,
                        'sidebar-label--show': dropdownShow,
                    }"
                >
                    {{
                        availableEvents.length > 1
                            ? `${service.firstName} ${service.lastName}`
                            : availableEvents[0].title
                    }}
                </div>

                <ul :class="dropdownShow && availableEvents.length > 1 ? 'sidebar-list active' : 'sidebar-list'">
                    <li
                        class="sidebar-list-event"
                        :class="event.eventStatus <= 1 ? 'event-grayed' : ''"
                        v-for="event in availableEvents"
                        :key="event.id"
                        @click="setActiveVideo(event)"
                    >
                        <!-- Active Entry Icon Set -->
                        <span v-if="event.id === activeEvent" class="event-icon">
                            <play-icon v-if="videoStatus === 'Paused' || videoStatus === 'Stopped'" />
                            <pause-icon v-else-if="videoStatus === 'Playing'" />
                            <v-progress-circular :size="25" indeterminate v-else-if="videoStatus === 'Waiting'" />
                        </span>

                        <!-- Non-Active Entry Icon Set -->
                        <span v-else class="event-icon">
                            <clock-icon v-if="event.eventStatus === 0 || event.eventStatus === 1" />
                            <play-icon v-else />
                        </span>

                        <!-- Event Info -->
                        <div class="event-info ">
                            <p class="event-info-title">{{ cleanTitle(event.title) }}</p>
                            <span class="event-info-description">{{ event.time | UTC_to_Local('MMM D, h:mm a') }}</span>
                        </div>
                    </li>
                </ul>
            </aside>
            <!-- END: Sidebar -->

            <!-- START: Video Wrapper -->
            <section class="video-wrapper">
                <span v-if="activeEventObject.eventStatus === 2 && videoStatus === 'Playing'" class="live-tag"
                    >LIVE</span
                >
                <!-- <div v-if="showSplashMessage" class="message-ending">
                    Thank You for Participating in this Service.
                </div> -->
                <div class="watermark" v-if="test">
                    <img
                        class="watermark-img"
                        src="https://videos.memoryshare.com/front-end-assets/test_overlay_white.png"
                    />
                </div>

                <span v-if="activeEventObject.eventStatus === 2" class="live-tag">LIVE</span>
                <video :fluid="true" ref="videoPlayer" class="video-js vjs-big-play-centered player" controls></video>
                <!-- <img v-if="service.logoURL" :src="service.logoURL" class="home-logo" /> -->
            </section>
            <!-- END: Video Wrapper -->
            <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" top>
                {{ snackbar.message }}
            </v-snackbar>
        </div>
    </div>
</template>
<script>
// ============================================================================
// IMPORTS
// ============================================================================
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import ViewTracker from '../utilities/analytics.js';

import { PlayIcon, PauseIcon, ClockIcon, LoaderIcon, LockIcon } from 'vue-feather-icons';

// ============================================================================
// SETTINGS
// ============================================================================
let sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const videoOptions = {
    autoplay: false,
    controls: true,
    errorDisplay: true,
    fluid: false,
    liveui: true,
    techOrder: ['html5'],
    controlBar: {
        children: ['playToggle', 'currentTimeDisplay', 'progressControl', 'volumePanel', 'fullscreenToggle'],
    },
    nativeControlsForTouch: false,
    notSupportedMessage: 'Waiting for live stream to start...',
};

videojs.addLanguage('en', {
    'The media could not be loaded, either because the server or network failed or because the format is not supported.':
        'Loading live stream...',
});

// ============================================================================
// EXPORT
// ============================================================================
export default {
    props: {
        service: {},
        options: {},
        slug: '',
        ended: {
            type: Number,
        },
        availableEvents: {},
        test: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentVideoSessionCount: 0,
            player: null,
            videoStatus: 'Initial',
            currentLiveEvent: 0,
            activeEvent: 0,
            activeEventObject: {},
            showSplashMessage: false,
            dropdownShow: true,
            started: false,
            snackbar: {
                show: false,
                message: '',
                timeout: 2000,
            },
        };
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, videoOptions, this.onPlayerReady);
        if (this.service.logoURL) {
            this.createLogoComponent(this.service.logoURL);
        }
        if (this.service) {
            this.setCustomizations();
        }

        this.viewTracker = new ViewTracker(this.player, this.slug);
    },
    beforeDestroy() {
        if (this.player) this.player.dispose();
    },
    methods: {
        setCustomizations() {
            document.documentElement.style.setProperty(
                '--custom-color1',
                this.service.videoPlayerPrimaryColor ? this.service.videoPlayerPrimaryColor : '#ab0658',
            );
            document.documentElement.style.setProperty(
                '--custom-color2',
                this.service.videoPlayerPrimaryColor ? this.service.videoPlayerSecondaryColor : '#ffffff',
            );
            document.documentElement.style.setProperty(
                '--custom-logo-width',
                this.service.videoPlayerLogoSize ? this.service.videoPlayerLogoSize + '%' : '10%',
            );
        },
        createLogoComponent(src) {
            // reference: https://jsbin.com/vobacas/edit?html,css,js,output
            const Component = videojs.getComponent('Component');
            const Logo = videojs.extend(Component, {
                constructor(player, options) {
                    Component.apply(this, arguments);
                },
                createEl() {
                    return videojs.createEl('img', {
                        className: 'vjs-home-logo',
                        src,
                    });
                },
            });

            videojs.registerComponent('Logo', Logo);

            this.player.addChild('Logo');
        },
        cleanTitle: function(title) {
            let cleaned = title.replace(`${this.service.firstName} ${this.service.lastName}'s `, '');
            return cleaned;
        },
        async onPlayerReady() {
            const defaultVolume = 0.5;

            this.player.volume(defaultVolume);
            // Update States
            this.currentLiveEvent = this.service.currentLiveEventId;

            // Register Events
            this.player.bigPlayButton.on('click', () => {
                this.player.muted(false);
                document.documentElement.style.setProperty('--variable-opacity', '0');
                document.documentElement.style.setProperty('--logo-position-height', '75px');
                let event = this.service.events.find(event => event.eventStatus > 1);
                if (event) this.setActiveVideo(event);
            });

            this.player.on('volumechange', e => {});

            this.player.bigPlayButton.on('touchstart', () => {
                this.player.muted(false);
                let event = this.service.events.find(event => event.eventStatus > 1);
                if (event) this.setActiveVideo(event);
            });

            this.player.on('waiting', () => {
                this.videoStatus = 'Waiting';
            });
            this.player.on('pause', () => {
                this.videoStatus = 'Paused';
            });
            this.player.on('playing', () => {
                this.showSplashMessage = false;
                this.videoStatus = 'Playing';
                this.started = true;

                this.checkForVideoUpdate();
            });
            this.player.on('ended', () => {
                this.showSplashMessage = true;
                this.videoStatus = 'Stopped';
            });
            this.player.on('error', async error => {
                let errorCode = error?.target.player.error_.code;

                // If the stream has started but the wowza link has populated, we just need to try
                // and refresh the video source
                if (errorCode === 2 || errorCode === 4) {
                    await sleep(5000);
                    this.checkForVideo();
                }
            });

            // Add Memory Share Logo To Controls
            let logo = this.player.controlBar.addChild('Component', {}, 12);
            logo.addClass('video-logo');

            // Find if Service is Live
            // If Service is Live, find the Live Event
            // And set it to active video
            if (this.currentLiveEvent) {
                let event = this.service.events.find(event => event.id == this.currentLiveEvent);
                if (event) this.setActiveVideo(event);
            }
        },
        refreshActiveVideo() {},
        async setActiveVideo(event) {
            // If interval is running to check live URL, clear it
            if (this.interval) {
                clearInterval(this.interval);
                this.interval = null;
            }

            // Set started to false
            this.started = false;

            // Reset
            this.splashMessageShow = false;

            // Check if Event Status is 0 || 1, which will not allow
            // the video to switch
            if (event.eventStatus === 0 || event.eventStatus === 1) {
                this.snackbar.message = 'This Event is Not Ready Yet!';
                this.snackbar.show = true;
                return;
            }

            // Hide Menu If In Player Only Mode
            if (this.options.playerOnly) this.dropdownShow = false;

            // Check if Event Clicked is currently Active
            if (this.activeEvent == event.id) {
                if (this.videoStatus === 'Playing') this.player.pause();
                if (this.videoStatus === 'Paused') this.player.play();
                if (this.videoStatus === 'Stopped') this.player.play();
                return;
            }

            // Check if Event is Private
            // Update Source
            this.player.src({
                type: event.convertedVideo ? 'video/mp4' : 'application/x-mpegURL',
                src: event.convertedVideo ? event.convertedVideo : event.liveURL,
            });

            // =========
            // Analytics
            // =========
            const isLive = event.id === this.service.currentLiveEventId;
            this.viewTracker.changeEvent(event.id, isLive);

            // Play
            this.player.play();

            // Update State
            this.activeEvent = event.id;
            this.activeEventObject = event;
        },
        checkForVideoUpdate() {
            setTimeout(() => {
                this.checkForVideoUpdate();
            }, 1000);
        },
        checkForVideo() {
            this.player.src({
                type: this.activeEventObject.convertedVideo ? 'video/mp4' : 'application/x-mpegURL',
                src: this.activeEventObject.convertedVideo
                    ? this.activeEventObject.convertedVideo
                    : this.activeEventObject.liveURL,
            });
            this.player.play();
            document.documentElement.style.setProperty('--logo-position-height', '75px');
        },
        // async isStreamReady(src) {
        //     try {
        //         const response = await this.axios.get(this.src);
        //         const doesExist = response.status === 200;
        //         return doesExist;
        //     } catch (error) {
        //         return false;
        //     }
        // },
        // async playLiveVideo(src, eventId) {
        //     const isStreamReady = await this.isStreamReady();

        //     if (isStreamReady) {
        //         // set source
        //         this.splashMessageShow = false;
        //         this.player.src({ type: 'application/x-mpegURL', src: this.src });

        //         if (this.activeEvent !== eventId) {
        //             const isLive = eventId === this.currentLiveEvent;
        //             this.viewTracker.changeEvent(eventId, isLive);
        //         }
        //         this.player.play();
        //     } else {
        //         this.splashMessage = 'Waiting for live stream to start...';
        //         this.splashMessageShow = true;
        //         this.interval = setTimeout(() => {
        //             this.playLiveVideo();
        //         }, 10000);
        //     }
        // },
    },
    watch: {
        service: function(service) {
            // Check if There is a live event active
            let liveEvent = service.events.find(event => event.id == service.currentLiveEventId);
            if (liveEvent) {
                this.activeEvent = liveEvent.id;
                this.activeEventObject = liveEvent;
                this.curr;
            }
        },
        availableEvents(newEvents, oldEvents) {
            const newActiveEvent = newEvents.find(event => event.id === this.activeEvent);
            const oldActiveEvent = oldEvents.find(event => event.id === this.activeEvent);

            // Make sure activeEventObject is up to date
            this.activeEventObject = newActiveEvent;

            // If active event was live, but is now paused or ended
            if (
                oldActiveEvent.eventStatus === 2 &&
                (newActiveEvent.eventStatus === 3 || newActiveEvent.eventStatus === 6)
            ) {
                // this.showSplashMessage = true;
                this.showSplashMessage = true;
                this.player.pause();
            }
        },
        videoStatus(status) {
            switch (status) {
                case 'Playing':
                    this.dropdownShow = false;
                    break;
                case 'Waiting':
                    this.dropdownShow = true;
                    break;
                case 'Paused':
                    this.dropdownShow = true;
                    break;
                case 'Stopped':
                    this.dropdownShow = true;
                    break;
                default:
                    console.warn(
                        "Hit default case in switch statement. This shouldn't happen, so you should look into this.",
                    );
                    break;
            }
            if (status !== 'Paused') return;
            this.dropdownShow = true;
        },
    },
    components: {
        PlayIcon,
        PauseIcon,
        LockIcon,
        LoaderIcon,
        ClockIcon,
    },
};
</script>

<style lang="scss">
@mixin for-phone-only {
    @media (max-width: 599px) {
        @content;

        .sidebar-label {
            font-size: 12px !important;
            padding: 0 0.25rem;
        }
    }
}

@mixin for-desktop-up {
    @media (min-width: 1100px) {
        @content;
    }
}
:root {
    --custom-color1: '';
    --custom-color2: '';
    --custom-logo-width: 4%;
    --variable-opacity: 1;
    --logo-position-height: 15px;
}

$control-bar-height: 60px;
$slider-height: $control-bar-height * 0.4;

.player-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .player-layout {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        max-height: 100vh;
        max-width: 80vw;

        @include for-desktop-up {
            grid-template-columns: 300px 1fr;
        }

        .video-js .vjs-big-play-button {
            font-size: 4em;
        }

        .sidebar {
            padding: 1rem;
            order: 2;

            @include for-desktop-up {
                order: 0;
            }

            &-label {
                background: var(--custom-color1) !important;
                border-radius: 5px;
                font-weight: 800;
                padding: 1rem;
                text-align: center;
                color: var(--custom-color2) !important;
                width: 100%;
                transition: 0.2s all;
                cursor: default;
            }

            &-list {
                margin-top: 1rem;
                display: flex;
                flex-direction: column;
                list-style: none;
                padding: 0;
                gap: 10px;

                &-event {
                    background: #414141;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    cursor: pointer;
                    transition: 0.3s all;

                    .event-icon {
                        color: white;
                        width: 30px;
                    }

                    .event-info {
                        line-height: 1.3rem;

                        &-title {
                            margin: 0;
                            color: #e0e0e0;
                            font-weight: 500;
                            font-size: 0.9rem;
                        }

                        &-description {
                            color: #787878;
                            font-size: 0.8rem;
                        }
                    }
                }

                &-event:hover {
                    background: #4b4b4b;
                }
            }
        }
    }

    .player-only-layout {
        width: 100%;
        height: 100%;
        position: relative;

        .video-js .vjs-big-play-button {
            font-size: max(3em, 6vw);
        }

        .sidebar {
            position: absolute;
            z-index: 1000;
            padding: 1rem;

            @include for-phone-only {
                width: 100%;
            }

            &-label {
                background: var(--custom-color1) !important;
                border-radius: 5px;
                font-weight: 600;
                padding: 0.25rem;
                text-align: center;
                color: var(--custom-color2) !important;
                transition: 0.2s all;
                padding: 1em;
                font-size: 18px !important;
                opacity: 0;

                @include for-phone-only {
                    font-size: 14px !important;
                    display: inline-block;
                }

                &--large {
                    padding: 1rem;
                    font-size: 24px !important;
                    width: auto;
                }

                &--show {
                    opacity: 1;
                }
            }

            &-list {
                margin-top: 0.875rem;
                display: flex;
                flex-direction: column;
                list-style: none;
                padding: 0;
                opacity: 0;
                height: 0px;
                transition: 0.5s all;
                overflow: auto;
                border-radius: 5px;

                @include for-phone-only {
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-right: 1rem;
                }

                /* ===== Scrollbar CSS ===== */
                /* Firefox */
                scrollbar-width: thin;
                scrollbar-color: #b0b0b0 #414141;

                /* Chrome, Edge, and Safari */
                &::-webkit-scrollbar {
                    width: 12px;
                }

                &::-webkit-scrollbar-track {
                    background: #414141;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #b0b0b0;
                    border-radius: 17px;
                    border: 3px solid #414141;
                }

                &-event {
                    background: #414141;
                    display: flex;
                    align-items: center;
                    padding: 8px 10px;
                    cursor: pointer;
                    transition: 0.2s all;

                    .event-icon {
                        color: white;
                        width: 30px;
                    }

                    .event-info {
                        line-height: 1.3rem;

                        &-title {
                            margin: 0;
                            color: #e0e0e0;
                            font-weight: 500;
                            font-size: 0.9rem;
                        }

                        &-description {
                            color: #787878;
                            font-size: 0.8rem;
                        }
                    }
                }
                /* 
                &-event:first-child {
                    border-top-left-radius: 5px;
                }

                &-event:last-child {
                    border-bottom-left-radius: 5px;
                } */

                &-event:hover {
                    background: #4b4b4b;
                }
            }
            &-list.active {
                height: auto;
                opacity: 1;
            }
        }
    }

    .event-grayed .event-info-title,
    .event-grayed .event-icon {
        color: #787878 !important;
    }

    .video-wrapper {
        height: 100%;
        position: relative;

        .watermark {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 101;
            pointer-events: none;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;

            .watermark-img {
                pointer-events: none;
                max-width: 100%;
                max-height: 100%;
                opacity: 0.9;
            }
        }

        .message-ending {
            background: rgb(23, 23, 23);
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 2rem;
        }

        .player {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .live-tag {
            position: absolute;
            z-index: 5000;
            background: var(--custom-color1);
            border-radius: 5px;
            padding: 0.25rem 0.75rem;
            font-size: 0.7rem;
            color: var(--custom-color2);
            font-weight: bold;
            right: 25px;
            top: 25px;
        }
    }

    // ========================================================================
    // VIDEOJS OVERWRITES
    // ========================================================================
    // https://codepen.io/heff/pen/EarCt?editors=0110 – Styling VideoJS reference

    .video-js {
        font-size: 10px;

        &:hover {
            cursor: pointer;
        }

        .vjs-tech {
            position: static !important;
        }

        // Big play button
        .vjs-big-play-button {
            background: var(--custom-color1) !important;
            border-color: var(--custom-color1) !important;
            border-radius: 5px;
            color: var(--custom-color2) !important;
            opacity: 0.9;
            transition: 0.2s all;
            /* font-size: min(6em, 10vw); */

            /* @media (min-width: 900px) {
                font-size: 6vw;
            } */
        }

        &:hover .vjs-big-play-button {
            background: var(--custom-color1) !important;
            border-color: var(--custom-color1) !important;
            opacity: 1;
        }

        // Control bar
        .vjs-control-bar {
            background-color: rgba(0, 0, 0, 0.6);
            padding: 0 2em;
            height: $control-bar-height;
        }

        // All control buttons (play, volume, full screen)
        .vjs-control {
            width: 3em;

            .vjs-icon-placeholder::before {
                font-size: 2em;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        // Play button
        .vjs-play-control {
            font-size: 1.3em;
        }

        // Progress bar
        .vjs-progress-control:hover .vjs-progress-holder {
            font-size: 1em; // Overrides videojs styles which make the bar get taller on hover
        }

        .vjs-progress-holder {
            height: $slider-height;
        }

        .vjs-play-progress {
            background: var(--custom-color1) !important;
        }

        .vjs-time-tooltip {
            font-size: 1.2em !important;
        }

        // Volume control
        .vjs-volume-control {
            height: 100% !important;
            align-items: center;
            margin-top: 0 !important; // Overrides top margin added when embedded
        }

        .vjs-mute-control {
            margin-top: 0 !important; // Overrides top margin added when embedded
        }

        .vjs-volume-bar.vjs-slider-horizontal {
            height: $slider-height;
        }

        .vjs-volume-level {
            height: 100% !important;

            &::before {
                display: none;
            }
        }

        // Remove X
        .vjs-error-display:before {
            display: none !important;
            content: '' !important;
        }
    }
}

.video-js .vjs-home-logo {
    width: var(--custom-logo-width);
    // max-width: 30%;
    max-width: 400px;
    opacity: var(--variable-opacity);
    position: absolute;
    bottom: var(--logo-position-height);
    right: 15px;
    transition: opacity 0.5s linear;
}

.video-js.vjs-paused.vjs-has-started .vjs-home-logo,
.video-js.vjs-user-active.vjs-has-started .vjs-home-logo {
    opacity: 1;
}

@media (max-width: 599px) {
    .sidebar {
        padding: 0.1rem !important;
    }
}
</style>
